import $ from 'jquery';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';
import './lib/cookieconsent.min';

import './components/clickable-carousel';
import './components/newsletter';

const Flickity = require('../../../node_modules/flickity/dist/flickity.pkgd.js');


let smoothScroll = {
  init: function () {
    $('a[href*="#"]:not([href="#"])').click(function () {
      if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
        let $target = $(this.hash);
        $target = $target.length ? $target : $('[name=' + this.hash.slice(1) + ']');

        if ($target.length) {
          let baseMinScrollTime = 400,
            baseMaxScrollTime = 800;

          let docHeight = $(document).height(),
            triggerTop = $(this).offset().top,
            targetTop = $target.offset().top;

          let scrollProportion = (targetTop - triggerTop) / docHeight,
            relativeTime = ((baseMaxScrollTime - baseMinScrollTime) * scrollProportion) + baseMinScrollTime,
            // Create inverse relationship (quicker the further we scroll)
            scrollTime = -1 * (1 - relativeTime);

          $('html, body').animate({
            scrollTop: targetTop - 75
          }, scrollTime);
          return false;
        }
      }
    });
  }
};
let carousels = {
  init: function () {
    //initialize all Flickity carousels with class .carousel

    $('.carousel').each(function () {

      $(this).flickity({
        // options
        cellAlign: 'left',
        contain: true,
        pageDots: true,
        wrapAround: false,
        cellSelector: '.carousel-cell',
        prevNextButtons: false,
        selectedAttraction: 0.03,
        friction: 0.6
      });

    });

    if ($('.carousel').length) {
      //resize to properly calculate offset containers
      $('.carousel').flickity('resize');
    }

    //handle next/prev buttons, only one carousel must be inside section
    $('.carousel-prev').on('click', function () {
      $(this)
        .closest('.carousel-wrapper')
        .find('.carousel')
        .flickity('previous');
    });

    $('.carousel-next').on('click', function () {
      $(this)
        .closest('.carousel-wrapper')
        .find('.carousel')
        .flickity('next');
    });
  }
};
$(document).ready(function () {

  $(document).foundation();

  $(".accordion").on("up.zf.accordion", function (event) {
    setTimeout(function () {
      $('html,body').animate({
        scrollTop: $('.is-active').offset().top - 80
      }, 'slow');
    }, 250);
  });


  smoothScroll.init();
  carousels.init();

  if (Foundation.MediaQuery.atLeast('medium')) {
    if ($("#steps-accordion").length) {
      $("#steps-accordion").foundation('destroy');
    }
  }

});
