import $ from "jquery";

// external js: flickity.pkgd.js

let $carousels = $('.carousel');

$carousels.on('staticClick.flickity', function (event, pointer, cellElement, cellIndex) {
  if (typeof cellIndex == 'number') {
    $(this).flickity('selectCell', cellIndex);
  }
});