import $ from "jquery";

$('#newsletter-form').submit(function (evt) {
  let form = $(evt.target);
  let data = form.serialize();
  let button = form.find('button')[0];
  button.innerHTML = 'Prihlasujem...';

  $.ajax({
      type: 'POST',
      url: form.data('action'),
      data: data,
      dataType: 'json',
      success: function (data) {
        if (data.result !== undefined && data.result.result === 'success') {
          form.remove();
          $('#newsletter-success').removeClass('hide');
          // TODO Google Analytics
          // ga('send', 'event', 'Newsletter', 'subscribe');
        }
      },
      complete: function () {
        button.innerHTML = 'Prihlásiť'
      }
    }
  );
  evt.preventDefault();
});
